<template>
  <div class="grid gap-4 grid-cols-1 lg:grid-cols-2 flex-grow">
    <div
      class="
        h-full
        lg:min-h-screen
        flex flex-col
        items-center
        justify-center
        px-5
        xl:px-0
      "
    >
      <!-- FORM EMAIL & PASSWORD -->
      <form class="w-full pl-0 max-w-500 flex-grow pt-24">
        <div class="flex flex-col items-start space-y-1 mb-8">
          <router-link to="/">
            <img
              :src="isPatient ? '/logos/56/logo.png' : '/logos/pro/pro.png'"
              alt="Dok.ma"
              class="hidden lg:block mb-16 max-w-190"
            />
          </router-link>
          <h3 class="text-black text-2xl font-EffraM">
            {{ $t("signin.se_connecter") }}
          </h3>
          <p class="text-black text-base font-EffraR">
            {{ $t("signin.accedez_a_votre_compte") }}
          </p>
        </div>

        <div
          v-if="tokenError"
          class="
            font-EffraR
            text-center
            bg-red-100
            text-red-600
            h-[45px]
            flex
            items-center
            justify-center
            rounded
            mb-8
          "
        >
          {{ tokenError }}
        </div>

        <div class="flex flex-col space-y-4 mb-10">
          <dok-input
            size="lg"
            :d-placeholder="$t('signin.email')"
            d-type="email"
            :d-model.sync="data.email"
            :label="$t('signin.email')"
            :custom-class="errors.email ? 'border-red-500' : ''"
          >
            <template v-slot:leftIcon>
              <img src="/icons/mail.png" alt="Email" />
            </template>
          </dok-input>

          <dok-input
            size="lg"
            :d-placeholder="$t('signin.password')"
            :d-type="!data.showPassword ? 'password' : 'text'"
            :d-model.sync="data.password"
            :label="$t('signin.password')"
            :custom-class="errors.password ? 'border-red-500' : ''"
          >
            <template v-slot:rightIcon>
              <img
                src="/icons/eye.png"
                class="cursor-pointer"
                @click="data.showPassword = !data.showPassword"
                alt="voir le mot de passe"
              />
            </template>
          </dok-input>

          <div class="flex justify-end">
            <router-link
              :to="{ name: 'forgetPassword' }"
              class="text-base font-EffraR"
              :class="isPatient ? 'text-rosDok-ultra' : 'text-dokBlue-ultra'"
            >
              {{ $t("forget_password") }} ?</router-link
            >
          </div>
        </div>

        <div class="flex flex-col gap-y-5 mb-12">
          <dok-button
            type="submit"
            :bg="isPatient ? 'rose' : 'blue'"
            size="md"
            custom-class="uppercase"
            @click.native="onClick"
          >
            {{ $t("se_connecter") }}
          </dok-button>
          <!-- <dok-button
            type="button"
            bg="rose"
            size="md"
            custom-class="uppercase"
            @click.native="onClickWithEmail"
          >
            {{ $t("se_connecter_with_email") }}
          </dok-button> -->
          <div class="flex flex-row items-center gap-x-3">
            <input
              id="remember"
              type="checkbox"
              class="
                form-checkbox
                border-2 border-solid border-gray-300
                text-xl
                focus:outline-none
              "
            />
            <label for="remember" class="font-EffraR text-lg">
              {{ $t("remember_me") }}
            </label>
          </div>
        </div>

        <div class="flex justify-center text-lg">
          <span class="font-EffraR text-black mr-1">
            {{ $t("idont_have_account") }}?
          </span>
          <router-link
            :to="{ name: 'newSingUp' }"
            class="font-EffraM"
            :class="isPatient ? 'text-rosDok-ultra' : 'text-dokBlue-ultra'"
          >
            {{ $t("create_account") }}
          </router-link>
        </div>
      </form>

      <!-- FOOTER -->
      <footer
        class="
          hidden
          lg:block
          w-full
          pl-0
          lg:pl-24
          pb-0
          lg:pb-10
          max-w-600
          font-EffraR
          text-base text-left
        "
        style="color: #ababab"
      >
        {{ $t("copyright") }}
      </footer>
    </div>
    <div :class="isPatient ? 'bg-signin-patient' : 'bg-signin-doctor'"></div>
  </div>
</template>

<script>
const dokFooter = () => import("./layouts/footer");

export default {
  components: { dokFooter },
  data() {
    return {
      errors: {},
      tokenError: null,
      data: {
        showPassword: false,
      },
    };
  },
  computed: {
    isPatient() {
      return this.$route.query.type && this.$route.query.type === "patient"
        ? true
        : false;
    },
  },
  mounted() {
    if (this.$route.query.errCode === "E_JWT_TOKEN_EXPIRED") {
      this.tokenError = "Votre session a expiré, reconnectez-vous";
    }
  },
  methods: {
    async onClick() {
      event.preventDefault();
      if (!this.data.email) {
        this.errors.email = "Email is requried";
      } else {
        delete this.errors.email;
      }
      if (!this.data.password) {
        this.errors.password = "Password is requried";
      } else {
        delete this.errors.password;
      }
      if (!this.data.email || !this.data.password) {
        return this.$forceUpdate();
      }
      this.errors = {};
      this.$vs.loading();
      const payload = { email: this.data.email, password: this.data.password };
      await this.$store.dispatch("auth/LOGIN", {
        userDetails: payload,
        onData: ({ ok, user, data, message, errCode, isFirstLogin }) => {
          this.$vs.loading.close();
          if (!ok && errCode === "E_TWO_FACTOR_REQUIRED") {
            return this.$router.push({
              name: "TwoFactorAuth",
              query: { token: data },
            });
          }
          if (user && !user.isActive) {
            this.$router.push({
              name: "verficationEmail",
              query: { email: user.email },
            });
          }
          if (!ok) {
            return this.$vs.notify({
              time: 2500,
              text: message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
          switch (user.role) {
            case "DOCTOR":
              if (isFirstLogin === true) {
                return this.$router.push({ name: "settings" });
              }
              window.location = "/agenda";
              return; //this.$router.push({ name: "agenda" });
            case "TEAM-MEMBER":
              if (user.roles[0] == "Calendrier") {
                return this.$router.push("/agenda");
              } else if (user.roles[0] == "Patientèle") {
                return this.$router.push("/patients");
              } else if (user.roles[0] == "Consultations") {
                return this.$router.push("/consultations");
              } else {
                return this.$router.push("/Settings");
              }
            case "PHARMACY" || "PHARMACY-MEMBER":
              window.location = "/pharmacy/prescription";
              break;
            case "LABORATORY" || "LABORATORY-MEMBER":
              window.location = "/laboratory/prescription";
              break;
            default:
              return this.$router.push({ name: "doctorsList" });
          }
          return this.$router.push({ name: "doctorsList" });
        },
      });
    },
    async onClickWithEmail() {
      // VALIDATION EMAIL ADDRESS
      if (!this.data.email) {
        this.errors.email = "Email is requried";
        this.$forceUpdate();
        return;
      }

      this.errors.email = null;
      this.$vs.loading();
      await this.$store.dispatch("user/LOGIN_WITH_EMAIL", {
        email: this.data.email,
        onData: ({ ok, data }) => {
          // MESSAGE ERROR
          this.$vs.loading.close();
          if (!ok) {
            return this.$vs.notify({
              time: 2500,
              text: "Erreur d'envoi du mail",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }

          return this.$vs.notify({
            time: 2500,
            text: "Mail envoyé avec succès",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        },
      });
    },
  },
  components: { dokFooter },
};
</script>
